import Service from '@root/common/base/Service';
import { ServiceInterface } from '@root/common/types/service';

import { FetchArticleExtraContent } from '@root/modules/article/types/article';

type ServiceVariables = FetchArticleExtraContent['variables'];

type FetchVariables = FetchArticleExtraContent['variables'];

type Response = FetchArticleExtraContent['data'];

export default class ArticleExtraContentService extends Service implements ServiceInterface {
  private async fetchArticleExtraContent(variables: FetchVariables) {
    const apiProvider = this.createProvider('HTTP');
    apiProvider.selectAPI('article-extra-content-api');

    try {
      const data = await apiProvider.request<Response>({
        method: 'get',
        params: {
          ch: variables.channelId,
          v3: 1,
        },
      });

      return data;
    } catch (error) {
      process.sentry?.captureException(`fetchArticleExtraContent error: ${error.message}`, {
        contexts: { data: { variables } },
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
      return null;
    }
  }

  public async fetch(variables: ServiceVariables) {
    const response = await this.fetchArticleExtraContent(variables);

    return response;
  }
}
