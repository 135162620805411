
import Vue from 'vue';
import rootConfig from '@root/config/root.config';
import { Data as ArticleData } from '@root/modules/article/types/article';
import SocialTooltip from '@root/common/components/base/SocialTooltip.vue';
import BookmarkButton from '@root/common/components/base/BookmarkButton.vue';
import { ExtendedProfile, PianoCustomerProfile } from '@piano/types/pianoState';

interface Props {
  article: ArticleData;
  data: {
    isLoggedIn: boolean;
    profile: PianoCustomerProfile | null;
    extendedProfile: ExtendedProfile | null;
  };
}

interface Computed {
  facebookUrl: string;
}

interface Data {
  popup: {
    width: number;
    height: number;
  };
  share: {
    title: string;
    url: string;
  };
  facebook: number;
}

interface Methods {
  openPopup: (url: string) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    SocialTooltip,
    BookmarkButton,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popup: {
        width: 383,
        height: 344,
      },
      share: {
        title: this.article.content.title.text,
        url: '',
      },
      facebook: this.article.statistics.shares || 0,
    };
  },
  computed: {
    facebookUrl() {
      return `${rootConfig.social_share.facebook}?u=${encodeURI(this.share.url)}&t=${encodeURI(this.share.title)}`;
    },
  },
  mounted() {
    const { baseUrl } = this.$channelConfig('meta').base;
    this.share.url = `${baseUrl}/a/${this.article.id}`;
  },
  methods: {
    openPopup(url) {
      if (window && window.open) {
        window.open(
          url,
          'popup_window',
          `width=${this.popup.width},height=${this.popup.height},directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no`
        );
      }
    },
  },
});
