
import Vue from 'vue';
import { Headlines as ArticleHeadlines } from '@root/modules/article/types/article';
import { DataWithHref, Data as HeadlinesData } from '@root/modules/headlines/types/headlines';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';
import ArticleRelatedHeadline from './ArticleRelatedHeadline.vue';

interface Methods {
  getHeadline: (headline: HeadlinesData) => DataWithHref;
}

interface Computed {
  headlineList: DataWithHref[];
}

interface Props {
  headlines: ArticleHeadlines;
  limit: number;
}

interface Data {
  domain: string;
  locale: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ArticleRelatedHeadline,
  },
  props: {
    headlines: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
  },
  data() {
    const { domain, locale } = this.$channelConfig('settings');
    return {
      domain,
      locale,
    };
  },
  computed: {
    headlineList() {
      const headlines = this.headlines.slice(0, this.limit).map((headline) => this.getHeadline(headline)) || [];
      return headlines;
    },
  },
  methods: {
    getHeadline(headline) {
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;
      return getHeadlineWithHref({ headline, domain: this.domain, locale: this.locale, fakeDomainEnabled });
    },
  },
});
