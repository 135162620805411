
import Vue from 'vue';
import { Data as HeadlinesData, DataWithHref } from '@root/modules/headlines/types/headlines';
import ArticleHeadline from './ArticleHeadline.vue';
import ArticleCustomHeadline from './ArticleCustomHeadline.vue';
import UniversalHeadlinesService from '@root/modules/headlines/services/UniversalHeadlines.service';
import ArticleCxenseService from '@root/modules/article/services/ArticleCxense.service';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';
import shuffle from 'lodash/shuffle';
import { State as ArticleState } from '@root/modules/article/types/article';
import { ContentMarketingList } from '@root/modules/article/types/getArticleExtraContent';

interface Methods {
  getHeadline: (headline: HeadlinesData) => DataWithHref;
  getCxenseHeadline: (headline: HeadlinesData) => DataWithHref;
  getCxenseHeadlines: () => void;
  getArticleHeadlines: (relatedHeadlineIds: number[]) => Promise<HeadlinesData[]>;
}

interface Computed {
  contentMarketingList: ContentMarketingList[] | null;
}

interface Data {
  domain: string;
  locale: string;
  language: string;
  cXenseHeadlinesLimit: number;
  cXenseHeadlines: HeadlinesData[];
  cXenseArticles: {
    id: string;
    clickUrl: string;
  }[];
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: {
    ArticleHeadline,
    ArticleCustomHeadline,
  },
  data() {
    const { domain, locale, lang } = this.$channelConfig('settings');
    return {
      domain,
      locale,
      language: lang.toUpperCase(),
      cXenseHeadlines: [],
      cXenseHeadlinesLimit: 6,
      cXenseArticles: [],
    };
  },
  computed: {
    contentMarketingList() {
      const articleExtra: ArticleState['articleExtraContent'] = this.$store.getters['article/getArticleExtraContent'];
      const contentMarketingList = articleExtra?.content_marketing_list;

      if (!contentMarketingList?.length) {
        return null;
      }

      const articlesAmount = this.cXenseHeadlines.length ? Math.ceil(this.cXenseHeadlines.length / 2) : this.cXenseHeadlinesLimit;

      return shuffle(contentMarketingList).slice(0, articlesAmount);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getCxenseHeadlines();
    });
  },
  methods: {
    getHeadline(headline) {
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;
      return getHeadlineWithHref({ headline, domain: this.domain, locale: this.locale, fakeDomainEnabled });
    },
    getCxenseHeadline(headline) {
      const cXenseArticle = this.cXenseArticles.find((article) => article.id === String(headline.id));
      return cXenseArticle ? { ...headline, href: cXenseArticle?.clickUrl } : this.getHeadline(headline);
    },
    async getCxenseHeadlines() {
      const widgetId = this.$channelConfig('page').article.component.cXense?.widgetId;

      if (!widgetId) {
        return false;
      }

      // Get cXense page context for more relevant data
      const articleCxenseService = new ArticleCxenseService();
      this.cXenseArticles = await articleCxenseService.fetch({ widgetId });
      this.cXenseHeadlines = await this.getArticleHeadlines(this.cXenseArticles.map((article) => Number(article.id)));
    },
    async getArticleHeadlines(relatedHeadlineIds) {
      const universalHeadlinesService = new UniversalHeadlinesService();
      const headlines = await universalHeadlinesService.fetch({
        id: relatedHeadlineIds,
        limit: this.cXenseHeadlinesLimit,
        language: this.language,
        contentMarketing: 'false',
      });

      return headlines.items;
    },
  },
});
