
import Vue from 'vue';
import { Data as ArticleData, Picture } from '@root/modules/article/types/article';
import ContentImage from '@core/components/UI/ContentImage.vue';
import PictureInfo from '@core/components/PictureInfo.vue';
import ContentFragment from '@core/components/ContentFragment.vue';
import articleConfig from '@root/modules/article/config/article.config';
import getArticleLeadPictureCropperProps from '@root/modules/article/utils/getArticleLeadPictureCropperProps';
import { GalleryData } from '@gallery/types/Gallery';
import Lightbox from '@gallery/components/lightbox/Lightbox.vue';

interface Props {
  article: ArticleData;
  size: string;
  position?: string;
}

interface Data {
  showLightbox: boolean;
  gallery: GalleryData;
  hasLightboxOpenedByHash: boolean;
}

interface Computed {
  leadElement: ArticleData['content']['leadElement']['content'][0] | null;
  picture: Picture | null;
  classes: Record<string, string | undefined>[];
  settings: Record<string, unknown>;
  authors: Array<string> | null;
  credit: string | null;
  caption: string | null;
}

interface Methods {
  toggleLightbox: () => void;
  assignPictureToGallery: () => void;
  toggleLightboxByUrlHash: () => void;
  initGallery: () => Promise<void>;
}

const sizeMap: Record<string, Record<string, unknown>> = articleConfig.infoCover;
const imageRatio = '16:9';

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ContentImage,
    ContentFragment,
    PictureInfo,
    Lightbox,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'center',
      required: false,
    },
  },
  data() {
    return {
      showLightbox: false,
      hasLightboxOpenedByHash: false,
      gallery: {
        id: '',
        metadata: {},
        images: {
          items: [],
          pager: {
            count: 0,
            limit: 0,
            offset: 0,
          },
        },
      },
    };
  },
  computed: {
    leadElement() {
      const { leadElement } = this.article.content;
      const leadElementContent = leadElement.content.length > 0 ? leadElement.content[0] : null;
      const isNotPicture = leadElementContent?.attrs?.type?.toLowerCase() !== 'image';
      return isNotPicture ? leadElementContent : null;
    },
    picture() {
      const { leadElement } = this.article.content;
      const leadElementContent = leadElement.content.length > 0 ? leadElement.content[0] : null;

      if (!leadElementContent) {
        return null;
      }

      const baseCropperData = getArticleLeadPictureCropperProps(leadElementContent.attrs.cropperData, imageRatio);

      leadElementContent.attrs.cropperData = {
        ...sizeMap[this.size],
        ...leadElementContent.attrs.cropperData,
        base: baseCropperData,
      };
      return leadElementContent;
    },
    classes() {
      return [
        {
          [`article-info-cover--${this.position}`]: this.position,
          [`article-info-cover--${this.size}`]: this.size,
        },
      ];
    },
    settings() {
      const settings = {
        shouldPlayerFloat: true,
      };

      if (this.leadElement) {
        const { embed } = this.$channelConfig('page').article.component.info;
        Object.assign(settings, embed);
      }
      return settings;
    },
    caption() {
      return this.picture?.attrs.metadata?.title || null;
    },
    authors() {
      return this.picture?.attrs.metadata?.authors?.length && this.picture.attrs.metadata.authors[0] ? this.picture.attrs.metadata.authors : null;
    },
    credit() {
      return this.picture?.attrs.metadata?.credit || null;
    },
  },
  watch: {
    async picture() {
      if (this.$isServer || !this.picture) {
        return;
      }

      await this.initGallery();
    },
  },
  async mounted() {
    await this.initGallery();
  },
  methods: {
    toggleLightbox() {
      if (!this.gallery.images.items.length) {
        return;
      }

      if (this.showLightbox) {
        this.showLightbox = false;
        document.body.style.overflow = '';
        history.replaceState(null, '', ' ');
      } else {
        this.showLightbox = true;
        document.body.style.overflow = 'hidden';
      }
    },
    assignPictureToGallery() {
      if (!this.picture) {
        return;
      }

      const { attrs } = this.picture || {};

      const picture = {
        id: String(attrs.id),
        metadata: {
          title: attrs.metadata?.title || '',
          authors: attrs.metadata?.authors || [],
          credit: attrs.metadata?.credit || '',
        },
      };

      this.gallery.images = {
        items: [picture],
        pager: {
          count: 1,
          limit: 1,
          offset: 0,
        },
      };
    },
    async toggleLightboxByUrlHash() {
      const { attrs } = this.picture || {};

      const hash = window.location.hash;
      const hashParams = new URLSearchParams(hash.substring(1));
      const articleImageId = hashParams.get('article-image-id') || '';

      if (!articleImageId || !attrs?.id || this.hasLightboxOpenedByHash) {
        return;
      }

      if (articleImageId === String(attrs.id)) {
        this.hasLightboxOpenedByHash = true;
        this.toggleLightbox();
      }
    },
    async initGallery() {
      this.assignPictureToGallery();
      await this.$nextTick();
      await this.toggleLightboxByUrlHash();
    },
  },
});
