import { Article } from '@root/modules/article/types/article.type';
import { WithContext, NewsArticle } from 'schema-dts';
import { Config } from '@root/modules/channel/types/channel';
import { buildAbsoluteRoute, extendRoutePath } from '@root/router/utils/buildRoutePath';

import { stripTags, truncate } from '@root/common/filters/htmlFormater';
import { convertToLocal } from '@root/common/filters/dateFormater';
import { getCustomCropsPerRatio, getFocuspointQueryString } from '@root/libs/api-provider/utils/pictureCustomCrops';
import { getEnv } from '@root/common/utils/getEnv';

function articleJsonld(baseMeta: Config['meta']['base'], article: Article, pathSuffix: string) {
  const pathOptions = {
    type: 'article',
    id: article.id,
    slug: article.slug,
    channel: article.primaryCategory.channel,
  };
  const url = buildAbsoluteRoute({ pathOptions });

  const discussionUrl = extendRoutePath({ route: url, suffix: pathSuffix });

  const articleLd: WithContext<NewsArticle> = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    url,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: article.content.title.text ? truncate(article.content.title.text, 100) : '',
    description: article.content.lead?.html ? stripTags(article.content.lead.html) : '',
    articleSection: article.primaryCategory?.name || '',
    commentCount: article.statistics.comments || 0,
    discussionUrl,
    datePublished: convertToLocal(article.publishAt || ''),
    dateModified: convertToLocal(article.updatedAt),
    keywords: article.tags?.items?.map((tag) => tag.name) || '',
    author: {
      '@type': 'Person',
      name: article.authors?.items?.map((author) => author.name).join(',') || baseMeta.title,
    },
    publisher: {
      '@type': 'Organization',
      name: baseMeta.title,
      logo: {
        '@type': 'ImageObject',
        url: baseMeta.imageData.src,
      },
    },
  };

  if (article.metaImage) {
    const picture = article.metaImage;
    const customCrops = picture?.attributes?.cropperData?.customCrops;
    const customCropsPerRatio = getCustomCropsPerRatio(customCrops);
    const focuspoint = picture?.attributes?.cropperData?.focuspoint;
    const focusPointString = getFocuspointQueryString(focuspoint);
    const url = getEnv('mediaApiCropper');
    articleLd.image = `${url}/${picture.id}.jpg?w=1200&h=800${focusPointString}${customCropsPerRatio['16:9']}`;
  }

  return articleLd;
}

export default articleJsonld;
