import { FetchHandler } from '@root/common/domain';

import { ArticleFetchState } from './ArticleFetchState.domain';
import { ArticleFetchContext } from './ArticleFetchContext.domain';

import { ArticleService } from '@root/modules/article/services/Article.service';

import { ArticleFetchDataError, ArticleFetchAccessError } from '@root/modules/article/errors';

import { doesArticleBaseDataExist } from '@root/modules/article/utils/doesArticleBaseDataExist';
import { getArticlePaywallState } from '@root/modules/article/utils/getArticlePaywallState';
import { fillArticleData } from '@root/modules/article/utils/fillArticleData';
import { ArticleAdapter } from '@root/modules/article/utils/ArticleAdapter';
import { isValidSignedInt32 } from '@root/common/utils/helpers';

import { FetchStatus, type FetchResponse } from '@root/common/types/domain';
import type { Article } from '@root/modules/article/types/article.type';

type ArticleFetchResponse = FetchResponse<Article, ArticleFetchDataError | ArticleFetchAccessError>;

export class ArticleFetchHandler extends FetchHandler<ArticleFetchState, ArticleFetchContext> {
  constructor(ArticleFetchState: ArticleFetchState, ArticleFetchContext: ArticleFetchContext) {
    super(ArticleFetchState, ArticleFetchContext);
  }

  /**
   * Validate article input before fetching data with service and return error if input is invalid
   */
  private handleInputError(id: number): ArticleFetchDataError | null {
    let articleFetchInputError: null | ArticleFetchDataError = null;

    if (isNaN(Number(id)) || !isValidSignedInt32(Number(id))) {
      this.state.fetchStatus = FetchStatus.Error;

      articleFetchInputError = new ArticleFetchDataError('Article fetch error - invalid input', {
        state: this.state,
        context: this.context,
      });

      articleFetchInputError.tags.responseCode = 400;
      articleFetchInputError.contexts.data.refetchType = this.state.refetchType;
    }

    return articleFetchInputError;
  }

  /**
   * Validate article access data after successful data fetch with service and return error if access is invalid
   */
  private handleAccessError(articleData: Article): ArticleFetchAccessError | null {
    let articleFetchAccessError: null | ArticleFetchAccessError = null;

    if (process.server) {
      return null;
    }

    const paywall = getArticlePaywallState(articleData.content.paywall);
    const isAccessInValid = paywall.enabled && this.context.customer.access && !paywall.access;

    if (isAccessInValid) {
      articleFetchAccessError = new ArticleFetchAccessError('Article fetch error - invalid access', {
        state: this.state,
        context: this.context,
      });

      articleFetchAccessError.contexts.data.refetchType = this.state.refetchType;
    }

    if (articleFetchAccessError) {
      this.state.fetchStatus = FetchStatus.Error;
    }

    return articleFetchAccessError;
  }

  /**
   * Fetch article data and handle internal article data logic
   */
  public async handleFetch(articleService: ArticleService): ArticleFetchResponse {
    const inputError = this.handleInputError(this.state.id);

    // Handle invalid article input error
    if (inputError) {
      return [null, inputError];
    }

    // Handle article data fetching with service
    const [serviceArticleResponse, serviceArticleError] = await articleService.fetch(this.state, this.context);

    if (serviceArticleError || !serviceArticleResponse?.article?.data?.length) {
      this.state.fetchStatus = FetchStatus.Error;

      const articleFetchDataError = new ArticleFetchDataError('Article fetch error - invalid data', {
        state: this.state,
        context: this.context,
      });

      if (serviceArticleError) {
        articleFetchDataError.clientMessage = serviceArticleError.message ?? articleFetchDataError.clientMessage;
        articleFetchDataError.tags.responseCode = serviceArticleError.statusCode ?? articleFetchDataError.tags.responseCode;
      } else if (!serviceArticleResponse?.article?.data?.length) {
        articleFetchDataError.tags.responseCode = 404;
      }

      articleFetchDataError.contexts.data.refetchType = this.state.refetchType;

      return [null, articleFetchDataError];
    }

    const articleResponse = serviceArticleResponse.article.data[0];

    // Adapt article data to make sure it is in the correct format
    const articleAdapter = new ArticleAdapter(articleResponse, this.context.channel.page.article.component);
    const adaptedArticle = articleAdapter.adapt();

    // Update article data:
    // - SSR request: fill article data with fetched partial article data without reader access
    // - CSR request(initial article request or on token change): update article data with fetched content data with reader access
    // - CSR request(not initial article): fill article data with fetched full article data with or without reader access
    const articleBaseDataExists = doesArticleBaseDataExist(this.state.article);
    const article = fillArticleData(adaptedArticle, this.state.article, {
      articleBaseDataExists,
    });

    const accessError = this.handleAccessError(article);

    // Handle article access error
    if (accessError) {
      return [null, accessError];
    }

    // Handle successful article data fetch
    this.state.fetchStatus = FetchStatus.Success;

    return [article, null];
  }
}
