import { Article } from '@root/modules/article/types/article.type';
import cloneDeep from 'lodash/cloneDeep';

import * as ArticleByID from '@root/modules/article/types/article.type';
import * as ArticleBodyByID from '@root/modules/article/types/articleBody.type';

/**
 * Update article content if article should or should not have reader rights to content
 *
 */
const updateArticleContent = (_article: Article, articleWithAccess: ArticleBodyByID.Article | ArticleByID.Article): Article => {
  const article = cloneDeep(_article);
  article.voiceSettings = articleWithAccess.voiceSettings;
  // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
  // @ts-ignore
  article.content.lead.content = articleWithAccess.content.lead.content;
  article.content.leadElement.content = articleWithAccess.content.leadElement.content;
  article.content.body.content = articleWithAccess.content.body.content;
  article.content.groupedBody = articleWithAccess.content.groupedBody;
  article.content.paywall.access = articleWithAccess.content.paywall.access;

  return article;
};

/**
 * Fill article data
 * Conditions:
 *  1. Fill all article data
 *  2. Update only article content data: trigger for initial article in CSR with reader access or on token change
 */
export const fillArticleData = (
  newArticleData: ArticleByID.Article | ArticleBodyByID.Article,
  currentArticleData: ArticleByID.Article | null,
  context: { articleBaseDataExists: boolean }
): ArticleByID.Article => {
  // Fill article data normally if no data exists
  if (!context.articleBaseDataExists || !currentArticleData) {
    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    return newArticleData;
  }

  // Update article data if content data was re-fetched
  return updateArticleContent(currentArticleData, newArticleData);
};
