
import Vue from 'vue';
import { Data as AuthorData } from '@root/modules/author/types/author';
import ContentImage from '@core/components/UI/ContentImage.vue';
import { CustomCrop } from '@root/common/types/fragment';

interface Props {
  author: AuthorData;
  size: number;
}

interface Computed {
  cropperData: Record<string, any>;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    ContentImage,
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  computed: {
    cropperData() {
      const customCrop: CustomCrop | null = this.author?.image?.attributes.cropperData.customCrops?.find((item: CustomCrop) => item.ratio === '1:1') || null;
      return { xs: { w: this.size, h: this.size, customCrop } };
    },
  },
});
