
import Vue from 'vue';
import { Data as ArticleData } from '@root/modules/article/types/article';

interface Props {
  showContentMarketing: boolean;
  showSubscriber: boolean;
  article: {
    id: ArticleData['id'];
  };
}

interface Computed {
  subscriberIcon: string;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    showSubscriber: {
      type: Boolean,
      required: true,
    },
    showContentMarketing: {
      type: Boolean,
      required: true,
    },
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    subscriberIcon() {
      const { lang } = this.$channelConfig('settings');
      let icon = '';

      try {
        icon = require(`!!html-loader!@root/assets/svg/subscribed_${lang}.svg`);
      } catch {
        icon = require(`!!html-loader!@root/assets/svg/subscribed.svg`);
      }

      return icon;
    },
  },
});
