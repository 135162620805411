
import Vue from 'vue';

interface Methods {
  confirmAdult: () => void;
}

interface Props {
  isFullTemplate: boolean;
}

interface Computed {
  classModifier: Record<string, unknown>;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  props: {
    isFullTemplate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classModifier() {
      return { 'article-adult-layer__container--full-template': this.isFullTemplate };
    },
  },
  methods: {
    confirmAdult() {
      this.$emit('confirm-adult');
    },
  },
});
