
import Vue from 'vue';
import { Data as ArticleData } from '@root/modules/article/types/article';
import ContentFragment from '@core/components/ContentFragment.vue';

interface Props {
  lead: ArticleData['content']['lead'];
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  components: {
    ContentFragment,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
});
