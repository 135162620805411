import { FetchState } from '@root/common/domain';
import type { FetchState as IFetchState, FetchStateInput } from '@root/common/types/domain';
import { Article } from '@root/modules/article/types/article.type';

interface IArticleFetchState extends IFetchState {
  id: number;
  article: Article | null;
}

export class ArticleFetchState extends FetchState implements IArticleFetchState {
  private _article: IArticleFetchState['article'];
  private _id: IArticleFetchState['id'];

  constructor(input: FetchStateInput<IArticleFetchState>) {
    super(input);
    this._article = input.article;
    this._id = input.id;
  }

  get id(): IArticleFetchState['id'] {
    return this._id;
  }

  set id(id: IArticleFetchState['id']) {
    this._id = id;
  }

  get article(): IArticleFetchState['article'] {
    return this._article;
  }

  set article(article: IArticleFetchState['article']) {
    this._article = article;
  }
}
