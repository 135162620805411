
import Vue from 'vue';
import { DataWithHref } from '@root/modules/headlines/types/headlines';
import { HeadlineTitle, HeadlineImage } from '@headlines/components/fragments';

interface Props {
  headline: DataWithHref;
  pictureSrcset: Record<string, unknown>;
}

interface Computed {
  classModifier: Record<string, unknown>;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    HeadlineImage,
    HeadlineTitle,
  },
  props: {
    headline: {
      type: Object,
      required: true,
    },
    pictureSrcset: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
});
