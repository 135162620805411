
import Vue from 'vue';
import { Author } from '@root/modules/article/types/article';
import getAliasByLocale from '@root/common/utils/getAliasByLocale';
import { TranslateResult } from 'vue-i18n';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import AuthorImage from '@root/modules/author/components/AuthorImage.vue';
import { Channel } from '@root/common/types/fragment';

interface Props {
  author: Author;
  showPictureEmail: boolean;
  showInfo: boolean;
  language: Channel['language'];
}

interface Computed {
  email: string | null;
  role: TranslateResult;
  authorLink: string;
  picture: string;
  position: string | null;
  showAuthorLink: boolean;
  description: string | null;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    LinkHandler,
    AuthorImage,
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
    showPictureEmail: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    showInfo: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    language: {
      type: String,
      required: true,
    },
  },
  computed: {
    email() {
      return this.author?.attributes?.find((attribute) => attribute.key === 'email')?.value || '';
    },
    role() {
      const role = this.author?.attributes?.find((attribute) => attribute.key === 'role')?.value;
      return this.$te(`article.author_roles.${role}`) ? this.$t(`article.author_roles.${role}`) : '';
    },
    authorLink() {
      if (this.author.author && this.author.author.id) {
        const authorId = this.author.author.id;
        const authorSlug = this.author.slug;

        const path = getAliasByLocale(this.$channelConfig('settings').locale, 'author');
        return `/${path}/${authorId}/${authorSlug}`;
      }
      return '';
    },
    picture() {
      return this.author?.image?.id || '';
    },
    position() {
      const positionEst = this.author?.attributes?.find((attribute) => attribute.key === 'position')?.value || '';
      const positionRu = this.author?.attributes?.find((attribute) => attribute.key === 'positionRu')?.value || '';
      return this.language === 'RU' ? positionRu : positionEst;
    },
    showAuthorLink() {
      return !!this.author?.attributes?.find((attribute) => attribute.key === 'authorPage')?.value;
    },
    description() {
      const descriptionKey = this.language === 'RU' ? 'descriptionRu' : 'description';
      return this.author?.attributes?.find((attribute) => attribute.key === descriptionKey)?.value || '';
    },
  },
});
