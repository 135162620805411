
import Vue from 'vue';
import { Data as ArticleData } from '@root/modules/article/types/article';
import PianoCustomerStateHandler from '@piano/components/PianoCustomerStateHandler.vue';
import ArticleAuthorList from '@root/modules/article/components/authors/ArticleAuthorList.vue';
import ArticleInfoSocial from '@root/modules/article/components/info/ArticleInfoSocial.vue';

interface Props {
  article: ArticleData;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  components: {
    ArticleAuthorList,
    ArticleInfoSocial,
    PianoCustomerStateHandler,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
});
