
import Vue from 'vue';
import { HeadlineTitle, HeadlineImage } from '@headlines/components/fragments';

interface Props {
  // Content API v2 type of headline
  headline: any;
  pictureSrcset: Record<string, unknown>;
}

interface Computed {
  classModifier: Record<string, unknown>;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    HeadlineImage,
    HeadlineTitle,
  },
  props: {
    headline: {
      type: Object,
      required: true,
    },
    pictureSrcset: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data() {
    return {
      adaptedHeadline: {
        href: this.headline.href,
        content: {
          title: {
            text: this.headline.title,
          },
        },
      },
    };
  },
});
