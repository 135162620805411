import Vue from 'vue';
import { Article } from '@root/modules/article/types/article.type';
import { buildRoutePath } from '@root/router/utils/buildRoutePath';
import { getArticleMeta, replaceArticleMeta } from '@root/modules/article/utils/articleMeta';
import documentReferrer from '@root/common/utils/documentReferrer';

import getArticleAdsSettings from '@root/modules/article/utils/getArticleAdsSettings';

interface Data {
  scrollDetected: boolean;
  removeListener: boolean;
  initScrollTop: number;
  scrollDirection: 'down' | 'up';
}

interface Computed {
  issuePath: string;
}

interface Methods {
  handleScroll: (event: Event) => void;
  updateHistory: () => void;
  setScrollDirection: () => void;
  isInViewport: (el: any) => boolean;
}

interface Props {
  article: Article;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      scrollDetected: false,
      initScrollTop: 0,
      scrollDirection: 'down',
      removeListener: false,
    };
  },
  computed: {
    issuePath() {
      const { domain } = this.$channelConfig('settings');
      const issue = this.$store.getters['issue/getIssueData'];
      const issueId = (issue && issue.id) || this.$route.params.issueid || null;

      if (!issueId) {
        return '';
      }

      const pathOptions = {
        type: 'issue',
        id: issue.id,
        channel: issue.channel,
      };

      return buildRoutePath({ pathOptions, domain });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initScrollTop = window.pageYOffset;
      window.addEventListener('scroll', this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    updateHistory() {
      if (!this.article) {
        return false;
      }
      const domain = this.issuePath ? undefined : this.$channelConfig('settings').domain;
      const pathOptions = {
        type: 'article',
        id: this.article.id,
        slug: this.article.slug,
        channel: this.article.primaryCategory.channel,
      };

      const articlePath = `${this.issuePath}${buildRoutePath({ pathOptions, domain })}`;
      if (window.location.pathname !== articlePath) {
        window.history.replaceState({}, this.$options.filters!.stripTags(this.article.content.title.html), articlePath);
        const meta = getArticleMeta({ $channelConfig: this.$channelConfig, article: this.article, path: document.location.pathname });
        replaceArticleMeta({
          title: meta.title,
          meta: meta.data,
          style: meta.style,
          link: meta.link,
          __dangerouslyDisableSanitizers: ['style'],
        });
        const prevArticlePath = this.$store.getters['article/getActiveArticlePath'];
        const host = this.$channelConfig('meta').base.baseUrl;
        documentReferrer(`${host}${prevArticlePath}`);

        this.$store.commit('article/setActiveArticle', this.article);
        this.$store.commit('article/setActiveArticlePath', articlePath);
        this.$store.commit(
          'adForm/setPageAdsSettings',
          getArticleAdsSettings({ $channelConfig: this.$channelConfig, $route: this.$route, article: this.article })
        );

        // Set issue active article ID to highlight issue headline in header sidebar
        if (this.issuePath) {
          this.$store.commit('article/setActiveArticleId', this.article.id);
        }
      }
    },
    setScrollDirection() {
      const scroll = window.pageYOffset;
      this.scrollDirection = scroll > this.initScrollTop ? 'down' : 'up';
      this.scrollDetected = scroll !== this.initScrollTop;
      this.initScrollTop = scroll;
    },
    isInViewport(el) {
      const clientRect = el.getBoundingClientRect();
      return clientRect.top >= 0 || clientRect.bottom >= 0;
    },
    handleScroll() {
      const el = this.$refs.article as HTMLElement;

      if (!(window && this.isInViewport(el))) {
        return false;
      }

      const { clientHeight } = document.documentElement;
      const articleRect = el.getBoundingClientRect();
      this.setScrollDirection();

      if (!this.scrollDetected) {
        return;
      }

      if (this.scrollDirection === 'down') {
        // User scrolls down
        // User has reached next article title (article is at the bottom of the viewport)) - update url
        if (clientHeight - articleRect.top > Math.round(window.innerHeight * 0.3) && articleRect.bottom > clientHeight) {
          this.updateHistory();
        }
      }

      if (this.scrollDirection === 'up') {
        // User scrolls up
        // User has scrolled over current article title (article still in the viewport) - update url
        const articleSizeCheck = articleRect.height < window.innerHeight ? window.innerHeight / 2 : 0;
        if (articleRect.top - articleSizeCheck < 0 && articleRect.bottom > window.innerHeight) {
          this.updateHistory();
        }
      }
      return this.removeListener;
    },
  },
});
