import { ArticleGroupedRibbons, ArticleExtraContent, Data as ArticleData } from '@root/modules/article/types/article';
import dayjs from 'dayjs';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

type GetArticleRibbons = (article: ArticleData, ribbons: ArticleExtraContent['ribbons_data']) => ArticleGroupedRibbons;

const ribbonPositions = <const>['top', 'body', 'bottom'];
const groupedRibbons: ArticleGroupedRibbons = {
  top: [],
  bottom: [],
  body: [],
};
const getArticleRibbons: GetArticleRibbons = (article, ribbons) => {
  const { id, primaryCategory, tags } = article;
  const { paywall } = article.content;

  const now = parseInt(dayjs().format('YYYYMMDDHHmm'), 10);
  // Initial ribbons filter by date and distribution
  const ribbonsByPositionDate = ribbons.filter((ribbon) => {
    const { ribbon_distribution, start, end } = ribbon.settings;
    const byDate = parseInt(start, 10) <= now && (!end || parseInt(end, 10) > now);
    const byDistribution =
      ribbon_distribution &&
      (ribbon_distribution === 'all' || (ribbon_distribution === 'paid' && paywall.enabled) || (ribbon_distribution === 'free' && !paywall.enabled));
    return byDate && byDistribution;
  });
  const ribbonsByCategoriesIds = ribbonsByPositionDate.filter((ribbon) => {
    return ribbon.settings.categories.includes(Number(primaryCategory.id));
  });
  const ribbonsByArticlesIds = ribbonsByPositionDate.filter((ribbon) => {
    return ribbon.settings.articles.includes(Number(id));
  });
  const ribbonsByTagsIds = ribbonsByPositionDate.filter((ribbon) => {
    return tags.items.some((tag) => ribbon.settings.tags.includes(Number(tag.id)));
  });
  const ribbonsByChannel = ribbonsByPositionDate.filter((ribbon) => {
    return ribbon.settings.all_articles;
  });
  const filteredRibbons = uniqWith([...ribbonsByCategoriesIds, ...ribbonsByArticlesIds, ...ribbonsByTagsIds, ...ribbonsByChannel], isEqual);

  // Restore initial order from api
  const titles = ribbonsByPositionDate.map((ribbon) => ribbon.settings.title);
  const orderedRibbons = filteredRibbons.sort((a, b) => {
    return titles.indexOf(a.settings.title) - titles.indexOf(b.settings.title);
  });
  if (orderedRibbons) {
    ribbonPositions.forEach((position) => {
      const ribbonsByPosition = orderedRibbons.filter((ribbon) => ribbon.settings.location === position);

      if (ribbonsByPosition) {
        groupedRibbons[position] = ribbonsByPosition;
      }
    });
  }

  return groupedRibbons;
};

export default getArticleRibbons;
