
import Vue from 'vue';
import ArticleAuthor from '@root/modules/article/components/authors/ArticleAuthor.vue';
import { Author } from '@root/modules/article/types/article';
import { Channel } from '@root/common/types/fragment';

interface Props {
  authors: Author[];
  language: Channel['language'];
}

interface Computed {
  showPictureAndEmail: boolean;
  showAuthorInfo: boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    ArticleAuthor,
  },
  props: {
    authors: {
      type: Array,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
  },
  computed: {
    showPictureAndEmail() {
      return this.authors.length <= 2;
    },
    showAuthorInfo() {
      return this.authors.length < 5;
    },
  },
});
