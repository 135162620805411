
import Vue from 'vue';
import SkeletonLoader from '@root/common/components/base/loader/SkeletonLoader.vue';

interface Computed {
  disabledAdsList: string[];
}

export default Vue.extend<unknown, unknown, Computed, unknown>({
  components: {
    SkeletonLoader,
  },
  computed: {
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
  },
});
