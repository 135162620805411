import { RootContext } from '@root/common/domain';
import type { RootContextInput } from '@root/common/types/domain';

type ArticleExposeContext = 'channel' | 'customer' | 'route';

export class ArticleFetchContext extends RootContext<ArticleExposeContext> {
  constructor(input: RootContextInput<ArticleExposeContext>) {
    super(input);
  }
}
