
import Vue from 'vue';
import { DataWithHref } from '@root/modules/headlines/types/headlines';
import { HeadlineTitle } from '@headlines/components/fragments';

interface Props {
  headline: DataWithHref;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  inject: {
    components: {
      default: {
        HeadlineTitle,
      },
    },
  },
  props: {
    headline: {
      type: Object,
      required: true,
    },
  },
});
