const getCropperProps = (cropperData: Record<string, any>, ratio = '16:9') => {
  let cropperProps: Record<string, unknown> = {};
  const { focuspoint = {}, customCrops = [] } = cropperData || {};

  if (focuspoint?.x !== undefined && focuspoint?.x !== null) {
    cropperProps.fx = focuspoint?.x;
  }

  if (focuspoint?.y !== undefined && focuspoint?.y !== null) {
    cropperProps.fy = focuspoint?.y;
  }

  if (customCrops && customCrops.length > 0) {
    customCrops.forEach((props: any) => {
      if (props.ratio === ratio) {
        cropperProps = { ...cropperProps, ...props };
      }
    });
  }

  return cropperProps;
};

export default getCropperProps;
