
import { ArticleGroupedRibbons, Headlines, Data as ArticleData, ArticleExtraContent } from '@root/modules/article/types/article';
import articleConfig from '@root/modules/article/config/article.config';

import articleScroll from '@root/modules/article/utils/articleScrollHandler';
import getArticleRibbons from '@root/modules/article/utils/getArticleRibbons';
// Article info section
import ArticleInfoNormalTemplate from '@root/modules/article/components/info/ArticleInfoNormalTemplate.vue';
import ArticleInfoFullTemplate from '@root/modules/article/components/info/ArticleInfoFullTemplate.vue';
import ArticleInfoSmallTemplate from '@root/modules/article/components/info/ArticleInfoSmallTemplate.vue';
import ArticleAdultLayer from '@root/modules/article/components/ArticleAdultLayer.vue';
import ArticleRibbons from '@root/modules/article/components/ArticleRibbons.vue';

// Article body components
import ArticleBody from '@root/modules/article/components/ArticleBody.vue';

import ArticleHeadlinesSection from '@root/modules/article/components/headlines/ArticleHeadlinesSection.vue';

import ArticleExtraContentService from '@root/modules/article/services/ArticleExtraContent.service';

interface Data {
  isAdultConfirmed: boolean;
}

interface Methods {
  confirmAdult: () => void;
}

interface Computed {
  topSection: string;
  topFragmentsNumber: number;
  isAdultArticle: boolean;
  isIssue: boolean;
  contentCenterClass: Record<string, boolean>;
  articleRibbons: ArticleGroupedRibbons | null;
}

interface Props {
  article: ArticleData;
  headlines: Headlines | [];
  isArticleFetching: boolean;
}

const topSectionMapping: Record<string, string> = {
  default: 'ArticleInfoNormalTemplate',
  fullImage: 'ArticleInfoFullTemplate',
  smallImage: 'ArticleInfoSmallTemplate',
  wideImage: 'ArticleInfoNormalTemplate',
};

export default articleScroll.extend<Data, Methods, Computed, Props>({
  components: {
    ArticleInfoNormalTemplate,
    ArticleInfoFullTemplate,
    ArticleInfoSmallTemplate,
    ArticleBody,
    ArticleHeadlinesSection,
    ArticleAdultLayer,
    ArticleRibbons,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    headlines: {
      type: Array,
      required: true,
    },
    isArticleFetching: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isAdultConfirmed: false,
    };
  },
  computed: {
    topSection() {
      const template = this.article.settings.articleTemplate;
      return topSectionMapping[template] || 'ArticleInfoNormalTemplate';
    },
    topFragmentsNumber() {
      const leadPosition = this.$channelConfig('page').article.component.info.leadPosition;
      return leadPosition === 'top' || this.topSection === 'ArticleInfoSmallTemplate' ? articleConfig.ads.first_position : 0;
    },
    isAdultArticle() {
      return this.article.settings.adultContent || false;
    },
    isIssue() {
      return this.$store.getters['issue/isIssue'];
    },
    contentCenterClass() {
      return { 'content-center': this.article.banners.disableAds };
    },
    articleRibbons() {
      const articleExtraContent: ArticleExtraContent = this.$store.getters['article/getArticleExtraContent'];
      if (!(articleExtraContent && articleExtraContent.ribbons_data)) {
        return null;
      }
      const articleRibbons = getArticleRibbons(this.article, articleExtraContent.ribbons_data);
      return articleRibbons;
    },
  },
  async mounted() {
    const { externalId: channelId } = this.$channelConfig('settings');

    // Get data for article ribbons and headlines for article bottom section
    const articleExtraContentService = new ArticleExtraContentService();
    const articleExtraContent = await articleExtraContentService.fetch({ channelId });
    this.$store.commit('article/setArticleExtraContent', articleExtraContent || null);

    this.$nextTick(() => {
      this.$emit('article-loaded', this.article.id);
    });
  },
  methods: {
    confirmAdult() {
      this.isAdultConfirmed = true;
    },
  },
});
