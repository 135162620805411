
import Page from '@root/common/base/Page';
import { ArticleFetchState, ArticleFetchContext, ArticleFetchHandler, ArticlePath, ArticleRedirectStatus } from '@root/modules/article/domain';

import { ArticleService } from '@root/modules/article/services';

import getArticleAdsSettings from '@root/modules/article/utils/getArticleAdsSettings';
import { fixUrlParams } from '@root/common/utils/url';
import { getArticleMeta } from '@root/modules/article/utils/articleMeta';
import { getArticleRelatedHeadlines } from '@root/modules/article/utils/getArticleRelatedHeadlines';
import { getArticlePaywallState } from '@root/modules/article/utils/getArticlePaywallState';
import { shouldEnableCustomerAccessForArticle, shouldDisableCustomerAccessForArticle } from '@root/modules/article/utils/checkCustomerAccessForArticle';

import ArticleSkeletonLoader from '@root/modules/article/components/ArticleSkeletonLoader.vue';
import ArticleContent from '@root/modules/article/components/ArticleContent.vue';
import articleJsonld from '@root/modules/article/utils/articleJsonld';

import { Article } from '@root/modules/article/types/article.type';
import { RelatedArticleRaw } from '@root/modules/article/types/article.raw.type';
import { RefetchType } from '~/src/common/types/domain';
import CustomerDataService from '@root/modules/customer/services/CustomerData.service';

interface Data {
  article: Article | null;
  articleRelatedHeadlines: RelatedArticleRaw[];
  isReadingHistoryBeingSent: boolean;
}

interface Props {
  articleId: number;
  initialArticle: boolean;
}

interface Computed {
  localArticle: Article | null;
  customerHasAccess: boolean;
  customerToken: string | null;
  isIssue: boolean;
  forceRefetch: boolean;
  pianoToken: string;
}

interface Methods {
  handleArticleLoad: (articleId: string) => void;
  handleRedirect: (article: Article) => void;
  sendReadingHistory(articleId: number): Promise<void>;
}

export default Page.extend<Data, Methods, Computed, Props>({
  components: {
    ArticleSkeletonLoader,
    ArticleContent,
  },
  props: {
    articleId: {
      type: Number,
      required: true,
    },
    initialArticle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      article: null,
      articleRelatedHeadlines: [],
      meta: null,
      isReadingHistoryBeingSent: false,
    };
  },
  async fetch() {
    const articlePageState = new ArticleFetchState({
      id: this.articleId,
      refetchType: this.refetch.activeType,
      article: this.article,
    });
    const articlePageContext = new ArticleFetchContext({
      route: this.$route,
      channel: this.$channelConfig('config'),
      customer: { token: this.customerToken, access: this.customerHasAccess },
    });

    const articleFetchHandler = new ArticleFetchHandler(articlePageState, articlePageContext);

    // Fetch data
    this.startFetching(articleFetchHandler.state.fetchStatus);
    const articleService = new ArticleService();
    const [article, articleError] = await articleFetchHandler.handleFetch(articleService);

    if (articleError) {
      // Try 1 refetch before throwing error in initial article fetch
      const willRefetch = this.handleFetchError(articleFetchHandler.state.fetchStatus, articleError);

      if (willRefetch) {
        return;
      }

      if (this.initialArticle) {
        this.handlePageError(articleError);
      } else {
        this.$emit('article-loaded', false);
      }

      return;
    }

    const articleAds = getArticleAdsSettings({ $channelConfig: this.$channelConfig, $route: this.$route, article });

    if (!this.isIssue) {
      this.handleRedirect(article);
    }

    // Set ads settings before article data is updated to make sure that ads are displayed correctly
    if (this.initialArticle) {
      // Set ads only for the first article, `articleScrollHandler.ts` handles updating ads for the subsequent articles
      this.$store.commit('adForm/setPageAdsSettings', articleAds);
    }
    this.$store.commit('article/setActiveArticle', article);
    this.$store.commit('category/setCategoryData', article.primaryCategory);

    // Set article data for the page after setting store data for optimal/correct rendering
    this.article = article;

    // Set related headlines for article bottom section
    if (this.articleRelatedHeadlines.length === 0) {
      this.articleRelatedHeadlines = getArticleRelatedHeadlines(article);
    }

    // Handle successful fetch
    this.stopFetching(articleFetchHandler.state.fetchStatus);
  },
  head() {
    if (this.article) {
      const meta = getArticleMeta({ $channelConfig: this.$channelConfig, article: this.article, path: this.$route.path });
      return {
        title: meta.title,
        meta: meta.data,
        style: meta.style,
        link: meta.link,
        __dangerouslyDisableSanitizers: ['style'],
      };
    }
    return {};
  },
  computed: {
    localArticle() {
      return this.article;
    },
    customerToken() {
      return this.$store.state.piano.ipAccessToken || this.$store.state.piano.token;
    },
    customerHasAccess() {
      return this.$store.state.piano.access.channelAccess;
    },
    isIssue() {
      return this.$store.getters['issue/isIssue'];
    },
    forceRefetch() {
      const { application } = this.$channelConfig('settings');
      return process.client && this.initialArticle && application.specialFeatures.forceArticleRefetch;
    },
    pianoToken() {
      return this.$store.state.piano.token;
    },
  },
  watch: {
    // Watch customer token change to detect if refetch should be triggered
    customerHasAccess(access: boolean) {
      const paywall = getArticlePaywallState(this.article?.content.paywall);

      // Refetch article in order to change the article access state depending if customer has reader rights
      // Triggered on log-in/log-off
      if (shouldEnableCustomerAccessForArticle(access, paywall) || shouldDisableCustomerAccessForArticle(access, paywall)) {
        this.addRefetchToQuery(RefetchType.Access);
      }
    },
    localArticle(article) {
      if (this.initialArticle) {
        fixUrlParams({
          type: 'article',
          locale: this.$channelConfig('settings').locale,
          route: this.$route,
          id: article?.id,
          slug: article?.slug,
        });

        this.$store.commit('article/setActiveArticlePath', this.$route.fullPath);
      }
    },
    pianoToken() {
      this.sendReadingHistory(this.articleId);
    },
  },
  beforeDestroy() {
    this.$store.commit('article/setActiveArticle', null);
    this.$store.commit('category/setCategoryData', null);
  },
  beforeMount() {
    if (this.initialArticle) {
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    // Force refetch for google bot to get access for article data
    if (this.forceRefetch) {
      this.addRefetchToQuery(RefetchType.Force);
    }
    this.sendReadingHistory(this.articleId);
  },
  jsonld() {
    if (!this.article) {
      return null;
    }

    const { base } = this.$channelConfig('meta');
    return articleJsonld(base, this.article, String(this.$t('portal_core.comments')));
  },
  methods: {
    handleArticleLoad(articleId) {
      this.$emit('article-loaded', Boolean(articleId));
    },
    handleRedirect(article: Article) {
      const articlePath = new ArticlePath(article);

      if (!articlePath.getState('isDataValid')) {
        this.$sentry.captureException(articlePath.getState('error'), {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
        return;
      }

      const redirectPath = articlePath.createPath({
        domain: this.$channelConfig('settings').domain,
        fakeDomainEnabled: this.$config.FAKE_DOMAIN_ENABLED,
        query: this.$nuxt.context.query,
        route: this.$nuxt.context.route,
      });

      if (articlePath.getState('redirectStatus') === ArticleRedirectStatus.Error) {
        this.$sentry.captureException(articlePath.getState('error'), {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
        return;
      }

      // FIXME: If an article with primaryChannel X is added to the channel Y issue
      //        then this part redirects the user from the issue view to the channel Y with the article view
      //        https://gitlab.delfi.net/home/issues/-/issues/13381
      if (articlePath.getState('redirectStatus') === ArticleRedirectStatus.Redirect) {
        this.handlePageRedirect(redirectPath);
      }
    },
    async sendReadingHistory(articleId) {
      if (this.isReadingHistoryBeingSent || !this.pianoToken) {
        return;
      }
      this.isReadingHistoryBeingSent = true;
      try {
        const customerDataService = new CustomerDataService();
        customerDataService.setCustomerToken(this.$store.state.piano.token);
        await customerDataService.createReadingHistory({ articleId });
      } catch (e) {
        this.$sentry.captureException(e, {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
      } finally {
        this.isReadingHistoryBeingSent = false;
      }
    },
  },
});
