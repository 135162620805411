
import Vue from 'vue';
import { Data as ArticleData } from '@root/modules/article/types/article';
import ArticleInfoSocial from '@root/modules/article/components/info/ArticleInfoSocial.vue';
import { extendRoutePath } from '@root/router/utils/buildRoutePath';
import PianoCustomerStateHandler from '@piano/components/PianoCustomerStateHandler.vue';

interface Props {
  article: ArticleData;
}

interface Computed {
  articleCommentsPath: string;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    ArticleInfoSocial,
    PianoCustomerStateHandler,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    articleCommentsPath() {
      return extendRoutePath({ route: this.$route.fullPath, suffix: String(this.$t('portal_core.comments')) });
    },
  },
});
