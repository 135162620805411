import Service from '@root/common/base/Service';
import { ServiceInterface } from '@root/common/types/service';

type fetchArticleCxenseContext = {
  userId: string;
  pageViewRandom: string;
  location: string;
};

interface cXWindow extends Window {
  cX: {
    getPageContext: () => fetchArticleCxenseContext;
  };
}

declare const window: cXWindow;

interface CxenseParams {
  widgetId: string;
  context?: {
    url: string;
    referrer?: string;
  };
  user?: {
    ids: {
      usi: string;
    };
  };
  prnd?: string;
}

type CxenseArticleData = {
  id: string;
  clickUrl: string;
};

type ServiceVariables = { widgetId: string };

type FetchVariables = { widgetId: string };

type Response = any;

export default class ArticleCxenseService extends Service implements ServiceInterface {
  private getCxensePageContext() {
    const cXensePageContext = process.client && window.cX && window.cX.getPageContext ? window.cX.getPageContext() : null;
    return cXensePageContext;
  }

  private async fetchArticleCxense(variables: FetchVariables) {
    const url = 'https://api.cxense.com/public/widget/data';
    const apiProvider = this.createProvider('HTTP');

    const params: CxenseParams = {
      widgetId: variables.widgetId,
    };
    const pageContext = this.getCxensePageContext();

    if (pageContext) {
      params.context = {
        url: pageContext.location,
      };
      params.user = {
        ids: {
          usi: pageContext.userId,
        },
      };
      params.prnd = pageContext.pageViewRandom;
    }

    const articles: CxenseArticleData[] = [];

    try {
      const data = await apiProvider.request<Response>({
        url,
        method: 'get',
        params: {
          json: JSON.stringify(params),
          widgetId: variables.widgetId,
          callback: `cXJsonpCB${+new Date()}`,
        },
      });

      // Parse jsonp string to json object
      const jsonData = JSON.parse(data.substring(data.indexOf('(') + 1, data.lastIndexOf(')')));
      jsonData.response.items.forEach((article: { [x: string]: string; click_url: string }) => {
        if (article['recs-articleid']) {
          articles.push({
            id: article['recs-articleid'],
            clickUrl: article.click_url,
          });
        }
      });
    } catch (error) {
      process.sentry?.captureException(`fetchArticleCxense error: ${error.message}`, {
        contexts: { data: { variables } },
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
    }

    return articles;
  }

  public async fetch(variables: ServiceVariables) {
    const response = await this.fetchArticleCxense(variables);

    return response;
  }
}
