
import Vue from 'vue';
import { Data as ArticleData } from '@root/modules/article/types/article';
import { Config } from '@root/modules/channel/types/channel';
import { AdaptedGetPlayerArticlesData, Article } from '@root/modules/player/types/PlayerArticles';
import { PlayerData } from '@root/modules/player/types/PlayerData';

interface Methods {
  togglePlay(): void;
  addToTasku(): Promise<void>;
}

interface Computed {
  infoSettings: Config['page']['article']['component']['info'];
  showListenButton: boolean;
  playerFetchedArticles: AdaptedGetPlayerArticlesData;
  currentPlayerData: PlayerData;
  currentlyPlayingArticle: Article | null;
  isCurrentArticleActiveInPlayer: boolean;
  isPlaying: boolean;
  isCurrentArticlePlaying: boolean;
  duration: string;
  customerToken: string;
  hasChannelSubscription: boolean;
  maintenanceMode: boolean;
  isArticleSaved: boolean;
  savedArticleId: string | undefined;
}

interface Props {
  article: ArticleData;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    infoSettings() {
      return this.$channelConfig('page').article.component.info;
    },
    playerFetchedArticles() {
      return this.$ttsPlayer?.state.articles || { items: [] };
    },
    showListenButton() {
      const showListenButtonEnabled = this.infoSettings.listenIconShow;
      const articleHasVoiceUrl = Boolean(this.article.voiceSettings.textToVoice.voiceUrl);
      const articleInPlayerFetchedArticles = this.playerFetchedArticles.items.find((playerFetchedArticle) => playerFetchedArticle.id === this.article.id);

      return Boolean(showListenButtonEnabled && articleHasVoiceUrl && articleInPlayerFetchedArticles);
    },
    currentlyPlayingArticle() {
      return this.$ttsPlayer?.state.currentlyPlayingArticle || null;
    },
    currentPlayerData() {
      return this.$ttsPlayer?.state.currentPlayerData || null;
    },
    isPlaying() {
      return this.currentPlayerData?.isPlaying || false;
    },
    isCurrentArticleActiveInPlayer() {
      return this.currentlyPlayingArticle?.id === this.article.id;
    },
    isCurrentArticlePlaying() {
      return this.isCurrentArticleActiveInPlayer && this.isPlaying;
    },
    duration() {
      return (
        (this.article.voiceSettings.textToVoice.audioDuration && `${Math.round(this.article.voiceSettings.textToVoice.audioDuration / (1000 * 60))} min`) || ''
      );
    },
    customerToken() {
      return this.$store.state.piano.token || this.$store.state.piano.ipAccessToken || '';
    },
    maintenanceMode() {
      return this.$store.state.piano.config.maintenanceMode;
    },
    hasChannelSubscription() {
      return this.$store.state.piano.access.channelAccess;
    },
    savedArticleId() {
      return this.$ttsPlayer?.state.savedArticles.items.find((article) => article.value === String(this.article.id))?.id;
    },
    isArticleSaved() {
      return Boolean(this.savedArticleId);
    },
  },
  methods: {
    async togglePlay() {
      if (this.isCurrentArticleActiveInPlayer) {
        this.$ttsPlayer.togglePlaying();
        return;
      }

      if (!this.maintenanceMode) {
        const loggedInWithSubscription = this.hasChannelSubscription && this.customerToken;

        if (this.article.content.paywall.enabled && !loggedInWithSubscription) {
          this.$store.commit('openPaywall', this.$t('paywall_modal.article_audio'));
          return;
        }

        if (!this.article.content.paywall.enabled && !this.customerToken) {
          await this.$store.dispatch('piano/showLoginModal', 'login');
          return;
        }
      }

      await this.$ttsPlayer.setupPlaylistByArticleId(this.article.id);
    },
    async addToTasku() {
      await this.$ttsPlayer.toggleSavedArticleInTasku(this.article.id);
    },
  },
});
