import { PagePathHandler, RedirectStatus, RedirectConditions } from '@root/common/base/PagePathHandler';
import matchChannel from '@root/common/utils/matchChannel';
import { buildRoutePath, buildQueryUrl } from '@root/router/utils/buildRoutePath';
import { Article } from '@root/modules/article/types/article.type';
import { Context } from '@nuxt/types';

export { RedirectStatus as ArticleRedirectStatus };

interface ArticlePathContext {
  domain: string;
  fakeDomainEnabled: boolean;
  query: Context['route']['query'];
  route: Context['route'];
}

type ArticleDeconstructed = Pick<Article, 'id' | 'slug' | 'primaryCategory'>;

/**
 * Create article path for redirecting to the correct channel
 */
export class ArticlePath extends PagePathHandler {
  private _articlePathData: ArticleDeconstructed | null = null;

  constructor({ id, slug, primaryCategory }: ArticleDeconstructed, options?: { redirectConditions: Partial<RedirectConditions> }) {
    super({
      name: 'ArticlePath',
      redirectConditions: {
        channel: true,
        id: false,
        slug: false,
        ...options?.redirectConditions,
      },
    });

    this.initializeAndValidate({ id, slug, primaryCategory });
  }

  /**
   * Initialize and validate the article data
   * Check if the article data is valid and set the error if not
   */
  private initializeAndValidate({ id, slug, primaryCategory }: ArticleDeconstructed): void {
    // TODO (types): .? operator should be removed after the article data types are fixed
    const channel = primaryCategory?.channel;
    const domain = channel?.domain;

    const isDataValid = Boolean(typeof id === 'number' && slug && channel && domain);

    this._isDataValid = isDataValid;
    this._articlePathData = isDataValid ? { id, slug, primaryCategory } : null;

    if (!this._isDataValid) {
      this._error = new Error(`${this._name} initializeAndValidate: Invalid article data - id: ${id}, slug: ${slug}, channel: ${JSON.stringify(channel)}`);
    }
  }

  /**
   * Create new path for the article redirect or return null if no redirect is needed
   */
  public createPath({ domain, fakeDomainEnabled, query, route }: ArticlePathContext): string | null {
    // Handle invalid data
    if (!this.isRedirectDataValid(this._articlePathData)) {
      return null;
    }

    const { id, slug, primaryCategory } = this._articlePathData;
    const { channel } = primaryCategory;
    const articleDomain = channel.domain;

    const pathOptions = { type: 'article', id, slug, channel };

    const sameChannel = matchChannel(domain, articleDomain);

    // Check redirect conditions
    if (!this.shouldRedirect({ id, slug, sameChannel, route })) {
      return null;
    }

    // Build the URL for redirect
    const url = buildRoutePath({ pathOptions, domain, fakeDomainEnabled });
    const queryUrl = buildQueryUrl(url, { query, route }) || null;

    // Handle invalid URL data
    // Skip redirect if the current URL is the same as the new URL - prevent infinite loop
    if (!this.isRedirectUrlValid(queryUrl, { domain, route })) {
      return null;
    }

    // Create the new path for redirection
    return this.handleRedirectPath(queryUrl);
  }
}
