import Service from '@root/common/base/Service';
import { ServiceInterface } from '@root/common/types/service';
import { QueryOptions } from '@apollo/client/core';

import { GetUniversalHeadlines } from '@root/modules/headlines/types/headlines';
import getUniversalHeadlines from '@root/modules/headlines/graphql/getUniversalHeadlines.graphql';
import { dataLoader } from '@root/libs/redis/dataloader/dataLoader';

type ServiceVariables = GetUniversalHeadlines['variables'];

type FetchVariables = GetUniversalHeadlines['variables'];

type Response = GetUniversalHeadlines['data'];

const emptyHeadlines = {
  items: [],
  pager: {
    offset: 0,
    count: 0,
  },
};

// TODO: unify getMostReadArticles with UniversalHeadlines (they are basically the same query)
export default class UniversalHeadlinesService extends Service implements ServiceInterface {
  private async fetchUniversalHeadlines(variables: FetchVariables) {
    try {
      const options = Object.assign({ query: getUniversalHeadlines }, { variables });
      const dataLoaderOptions = {
        remote: {
          keyPrefix: 'universal_headlines',
        },
        cacheKeyFn: (params: Record<string, unknown>) => {
          const key = JSON.stringify(params.variables);
          return key;
        },
      };
      const requestWrapper = async (options: QueryOptions): Promise<Response | Error> => {
        const apiProvider = this.createProvider('GraphQL');
        apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
        const response = await apiProvider.query<Response>(options);

        this.throwGraphqlOrApolloErrorIfExists(response);

        return response.data;
      };
      const cacheData = variables.offset === 0 || !variables.search;
      const redisDataLoader = dataLoader<QueryOptions, Response | Error>(requestWrapper, dataLoaderOptions);
      const response: Response | Error = redisDataLoader && cacheData ? await redisDataLoader.load(options) : await requestWrapper(options);

      if (response instanceof Error) {
        throw response;
      }

      if (!response?.headlines?.items.length) {
        // FIXME: Throw error-like object
        throw 'Empty headlines';
      }

      return {
        items: response?.headlines?.items || [],
        pager: response?.headlines?.pager,
      };
    } catch {
      return emptyHeadlines;
    }
  }

  public async fetch(variables: ServiceVariables) {
    const response = await this.fetchUniversalHeadlines(variables);

    return response;
  }
}
