import { QueryOptions } from '@apollo/client/core';
import { dataLoader } from '@root/libs/redis/dataloader/dataLoader';

import Service from '@root/common/base/Service';

import { ArticleFetchState, ArticleFetchContext } from '@root/modules/article/domain';

import { doesArticleBaseDataExist } from '@root/modules/article/utils/doesArticleBaseDataExist';
import { getArticlePaywallState } from '@root/modules/article/utils/getArticlePaywallState';

import getArticleByID from '@root/modules/article/graphql/getArticleByID.graphql';
import getArticleBodyByID from '@root/modules/article/graphql/getArticleBodyByID.graphql';

import type { Config } from '@root/modules/channel/types/channel';
import type { ServiceResponse } from '~/src/common/types/service';
import type { ArticleByIDApiResponseRaw } from '@root/modules/article/types/article.raw.type';
import type { ArticleBodyByIDApiResponseRaw } from '@root/modules/article/types/articleBody.raw.type';

type ArticleServiceVariables = {
  id: number;
  channelId: string;
  language?: string;
  preview?: string | null;
  customerToken?: string | null;
  application?: Config['settings']['application'];
  settings?: {
    requestBody: boolean;
    authorizationHeader?: boolean;
  };
};

type ArticleApiResponse = ArticleByIDApiResponseRaw | ArticleBodyByIDApiResponseRaw;
interface ArticleFetchVariables {
  id: number;
  authorLanguage?: string;
}

export class ArticleService extends Service {
  constructor() {
    super({ serviceType: 'article' });
  }

  /**
   * Get the query with options based on the provided service variables
   */
  private getQueryWithOptions(serviceVariables: ArticleServiceVariables) {
    const { id, preview, customerToken, settings, language } = serviceVariables;
    const variables: ArticleFetchVariables = { id: Number(id), authorLanguage: language };

    const query = settings?.requestBody ? getArticleBodyByID : getArticleByID;

    const queryWithOptions = {
      query,
      options: {
        variables,
        context: { headers: {} as Record<string, unknown> },
      },
    };

    // If preview is enabled, add preview header to the request
    // Should give access to article based on preview token
    if (preview) {
      queryWithOptions.options.context.headers.preview = preview;
      return queryWithOptions;
    }

    // If customer token is not provided, return the query with options
    // No access is provided to article
    if (!customerToken) {
      return queryWithOptions;
    }

    // If authorization header is required, add the customer token to the request
    // Should give access to article based on customer token
    if (settings?.authorizationHeader) {
      queryWithOptions.options.context.headers.Authorization = `Bearer ${customerToken}`;
    }

    return queryWithOptions;
  }

  /**
   * Get the service variables required for making a request
   */
  private getServiceVariables(state: ArticleFetchState, context: ArticleFetchContext): ArticleServiceVariables {
    const articleBaseDataExists = doesArticleBaseDataExist(state.article);
    const paywall = getArticlePaywallState(state.article?.content.paywall);

    const { externalId: channelId, application, lang } = context.channel.settings;

    // If article base data exists, use paywall.enabled as authorization header
    // If article base data does not exist, use true as authorization header because there is no way to know if the article is behind a paywall
    // If is server-use use false as authorization header because server request does not need authorization header
    const authorizationHeader = articleBaseDataExists ? paywall.enabled : process.server ? false : true;

    const variables: ArticleServiceVariables = {
      id: state.id,
      channelId,
      application,
      language: lang.toUpperCase(),
      customerToken: context.customer.token,
      preview: context.route.query.preview as string,
      settings: {
        requestBody: articleBaseDataExists,
        authorizationHeader,
      },
    };

    return variables;
  }

  /**
   * Fetch article data either from redis or from API
   * Store data with redisDataLoader if data is fetched from API
   */
  public async fetch(state: ArticleFetchState, context: ArticleFetchContext): ServiceResponse<ArticleApiResponse> {
    const serviceVariables = this.getServiceVariables(state, context);
    const { preview, application } = serviceVariables;

    const { query, options: queryOptions } = this.getQueryWithOptions(serviceVariables);
    // Use redis data if preview is not enabled and application is not search bot
    const useRedisData = !preview && !application?.isSearchBot;

    const options = Object.assign({ query }, queryOptions);
    const dataLoaderOptions = {
      remote: {
        keyPrefix: 'article',
      },
    };

    // Create a request wrapper to handle API requests
    const requestWrapper = async (options: QueryOptions): Promise<ArticleApiResponse | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
      const response = await apiProvider.query<ArticleApiResponse>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      return response.data;
    };

    const redisDataLoader = dataLoader<QueryOptions, ArticleApiResponse | Error>(requestWrapper, dataLoaderOptions);

    // Fetch data from redis or API
    const response: ArticleApiResponse | Error =
      redisDataLoader && useRedisData
        ? await redisDataLoader.load(options)
        : await this.requestWrapperHandler<ArticleApiResponse | Error>(() => requestWrapper(options));

    // Handle internal graphql errors
    if (response instanceof Error) {
      const errorData = this.generateErrorData(response);
      return [null, errorData];
    }

    return [response, null];
  }
}
