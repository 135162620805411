
import Vue from 'vue';
import { Data as ArticleData } from '@root/modules/article/types/article';

interface Props {
  summary: ArticleData['content']['summary'];
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
});
