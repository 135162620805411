import { SentryError, type ErrorContext, type ErrorTags, type ServiceErrorTags } from '@root/common/base/SentryError';
import { ArticleFetchState, ArticleFetchContext } from '@root/modules/article/domain';
import { ArticleFetchErrorReason } from '@root/modules/article/errors/ArticleFetch.error';

interface ArticleFetchErrorContext {
  cause: {
    reason: ArticleFetchErrorReason.InvalidAccess;
  };
  data: {
    id: ArticleFetchState['id'];
    article: ArticleFetchState['article'];
    refetchType: ArticleFetchState['refetchType'];
    paywall: NonNullable<ArticleFetchState['article']>['content']['paywall'] | null;
    customer: {
      access: ArticleFetchContext['customer']['access'];
      token: boolean;
    };
  };
}

export class ArticleFetchAccessError extends SentryError<ArticleFetchErrorContext, ServiceErrorTags> {
  constructor(message: string, { state, context }: { state: ArticleFetchState; context: ArticleFetchContext }) {
    super(message);
    this.clientMessage = 'article.error.not_found';

    const sentryContext: ErrorContext<ArticleFetchErrorContext> = {
      cause: {
        reason: ArticleFetchErrorReason.InvalidAccess,
      },
      data: {
        id: state.id,
        article: state.article,
        refetchType: state.refetchType,
        customer: {
          access: context.customer.access,
          token: Boolean(context.customer.token),
        },
        paywall: state.article?.content.paywall || null,
      },
    };

    const sentryTags: ErrorTags<ServiceErrorTags> = {
      apiType: 'content',
      responseCode: 500,
    };

    this.setInitialContexts(sentryContext);
    this.setInitialTags(sentryTags);
  }
}
