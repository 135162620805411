import { Article } from '@root/modules/article/types/article.type';
import { RelatedArticleRaw } from '@root/modules/article/types/article.raw.type';

/**
 * Get article related headlines for rendering of list of related articles
 */

export const getArticleRelatedHeadlines = ({ relatedEntities }: Pick<Article, 'relatedEntities'>): RelatedArticleRaw[] => {
  if (!relatedEntities.articles.items?.length) {
    return [];
  }

  const articleRelatedHeadlines: RelatedArticleRaw[] = [];

  for (const relatedItem of relatedEntities.articles.items) {
    if (relatedItem.article) {
      articleRelatedHeadlines.push(relatedItem.article);
    }
  }

  return articleRelatedHeadlines;
};
