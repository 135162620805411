/**
 * Check if customer has access to article and if API is aware of it
 */
export const shouldEnableCustomerAccessForArticle = (userHasAccess: boolean, paywall: { enabled: boolean; access: boolean }): boolean => {
  return paywall.enabled && userHasAccess && !paywall.access;
};

/**
 * Check if customer does not have access to article and if API is aware of it
 */
export const shouldDisableCustomerAccessForArticle = (userHasAccess: boolean, paywall: { enabled: boolean; access: boolean }): boolean => {
  return paywall.enabled && !userHasAccess && paywall.access;
};
