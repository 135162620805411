import { ArticleContentRaw } from '@root/modules/article/types/article.raw.type';

type Paywall = Pick<ArticleContentRaw['paywall'], 'enabled' | 'access'>;
/**
 * Get article paywall state
 */
export const getArticlePaywallState = (paywall: Paywall | undefined): { enabled: boolean; access: boolean } => {
  const enabled = paywall?.enabled ?? false;
  const access = paywall?.access || false;

  return {
    enabled,
    access,
  };
};
