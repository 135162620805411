
import Vue from 'vue';
import { Data as ArticleData, Headlines, ContentFragment as ArticleContentFragment } from '@root/modules/article/types/article';

import ContentRecipe from '@core/components/UI/ContentRecipe.vue';
import ContentFragment from '@core/components/ContentFragment.vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import ArticleBodyBottom from '@root/modules/article/components/ArticleBodyBottom.vue';
import ArticleRelatedHeadlinesList from '@root/modules/article/components/headlines/ArticleRelatedHeadlinesList.vue';
import ArticleFeedback from '@root/modules/article/components/feedback/ArticleFeedback.vue';

import BodySkeletonLoader from '@root/modules/article/components/BodySkeletonLoader.vue';
const ArticlePaywall = () => import(/* webpackChunkName: "ArticlePaywall" */ '@root/modules/article/components/paywall/ArticlePaywall.vue');

interface Props {
  article: ArticleData;
  headlines: Headlines | [];
  isArticleFetching: boolean;
  isAdultConfirmed: boolean;
}

interface Computed {
  articleGroupedBody: ArticleData['content']['groupedBody'];
  isMobile: boolean;
  disabledAdsList: string[];
  disableAds: boolean;
}

interface Data {
  initFragmentsGroup: FragmentsGroup;
  relatedArticlesSettings: {
    minParagraphsLength: number;
    afterParagraphPosition: number;
    articlesLimit: number;
    endOfArticleBody: boolean;
  };
}

type Fragment = ArticleContentFragment;

interface FragmentsGroup {
  items: (Fragment | string)[];
  options: {
    skipAds: boolean;
    addRelatedHeadlines: boolean;
    relatedPosition: number;
  };
}

export default Vue.extend<Data, unknown, Computed, Props>({
  components: {
    AdFormBanner,
    ArticleBodyBottom,
    ArticleFeedback,
    ContentFragment,
    ContentRecipe,
    ArticleRelatedHeadlinesList,
    ArticlePaywall,
    BodySkeletonLoader,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    headlines: {
      type: Array,
      required: true,
    },
    isArticleFetching: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdultConfirmed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      initFragmentsGroup: {
        items: [],
        options: {
          skipAds: false,
          addRelatedHeadlines: false,
          relatedPosition: 1,
        },
      },
      relatedArticlesSettings: this.$channelConfig('page').article.component.relatedArticles,
    };
  },
  computed: {
    isMobile() {
      return this.$ua && this.$ua.deviceType() === 'smartphone' && !this.$ua.isFromTablet();
    },
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
    disableAds() {
      return this.article.banners.disableAds;
    },
    articleGroupedBody() {
      return this.article.content.groupedBody;
    },
  },
});
