
import Vue from 'vue';
import { ArticleExtraContent } from '@root/modules/article/types/article';
import { getBaseDomain } from '@root/common/utils/url';
import ContentFragment from '@core/components/ContentFragment.vue';

interface Data {
  allowedDomains: string[];
}
interface Props {
  location: string;
  ribbons: ArticleExtraContent['ribbons_data'];
}
interface Methods {
  handleDocHeightMsg: (event: MessageEvent) => void;
}
export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    ContentFragment,
  },
  props: {
    ribbons: {
      type: Array,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allowedDomains: ['.delfi.ee', '.delfi.net'],
    };
  },
  mounted() {
    // Add responsive iframes/content listener
    // deepcode ignore InsufficientPostmessageValidation: <validation is made in handleDocHeightMsg>
    window.addEventListener('message', (event) => this.handleDocHeightMsg(event), false);
  },
  destroyed() {
    // Remove responsive iframe/content listener
    window.removeEventListener('message', this.handleDocHeightMsg);
  },
  methods: {
    // Change iframes height dynamically
    handleDocHeightMsg(event) {
      const origin = getBaseDomain();
      // Accept only delfi domains
      const isAllowed = this.allowedDomains.some((domain) => origin === domain);
      if (isAllowed) {
        try {
          const data = JSON.parse(event.data);

          if (data.id && data.docHeight) {
            const el = document.getElementById(data.id);
            if (el) {
              el.style.height = `${data.docHeight}px`;
            }
          }
        } catch (error) {
          return false;
        }
      }
      return true;
    },
  },
});
