
import Vue from 'vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { getArticlePaywallState } from '@root/modules/article/utils/getArticlePaywallState';
import { Data as ArticleData } from '@root/modules/article/types/article';

interface Props {
  adsDisabled: boolean;
  article: ArticleData;
}

interface Data {
  feedbackOptions: FeedbackOption[];
  selectedKey: string | null;
  selectedIcon: string | null;
  feedbackSubmitted: boolean;
}

interface FeedbackOption {
  icon: string;
  key: string;
}

interface Computed {
  showArticleFeedback: boolean;
  isFeedbackAllowedByChannel: boolean;
  isFeedbackAllowedByCategory: boolean;
  isArticleAccessible: boolean;
}

interface Methods {
  selectOption(option: FeedbackOption): void;
  submitFeedback(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BaseIcon,
  },
  props: {
    adsDisabled: {
      type: Boolean,
      required: true,
    },
    article: {
      type: Object as () => ArticleData,
      required: true,
    },
  },
  data(): Data {
    return {
      feedbackOptions: [
        { icon: 'emoji-face-smiling', key: 'happy' },
        { icon: 'emoji-face-hushed', key: 'surprised' },
        { icon: 'emoji-light-bulb', key: 'smarter' },
        { icon: 'emoji-face-blank', key: 'indifferent' },
        { icon: 'emoji-face-crying', key: 'sad' },
        { icon: 'emoji-face-angry', key: 'angry' },
      ],
      selectedKey: null,
      selectedIcon: null,
      feedbackSubmitted: false,
    };
  },
  computed: {
    isFeedbackAllowedByChannel(): boolean {
      return this.$channelConfig('page')?.article?.component?.feedback?.enabled ?? false;
    },
    isFeedbackAllowedByCategory(): boolean {
      const blacklistCategories = this.$channelConfig('page')?.article?.component?.feedback?.blacklistCategory ?? [];
      return !blacklistCategories.includes(Number(this.article.primaryCategory.id));
    },
    isArticleAccessible(): boolean {
      const { enabled, access } = getArticlePaywallState(this.article?.content?.paywall);
      return !enabled || access;
    },
    showArticleFeedback(): boolean {
      return this.isFeedbackAllowedByChannel && this.isFeedbackAllowedByCategory && this.isArticleAccessible && !this.adsDisabled;
    },
  },
  methods: {
    selectOption(option: FeedbackOption) {
      this.selectedKey = option.key;
      this.selectedIcon = option.icon;
    },
    submitFeedback() {
      if (!this.selectedKey) {
        return;
      }

      this.feedbackSubmitted = true;
      this.$store.commit('analytics/setClickEvent', {
        cXense: {
          eventName: 'ARTICLE_REACTIONS',
          eventData: {
            type: 'reaction',
            data: {
              emotion: this.selectedKey,
              isPaywalledArt: this.article.content.paywall.enabled ? 'yes' : 'no',
              isDesktopView: this.$ua.deviceType() === 'pc' ? 'yes' : 'no',
            },
          },
        },
      });
    },
  },
});
