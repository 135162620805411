import { Article } from '@root/modules/article/types/article.type';

/**
 * Check if article base data exists
 * @returns boolean If article has been fetched in SSR and has all necessary data then this function will return true
 */
export const doesArticleBaseDataExist = (article: Article | null): boolean => {
  if (!article) {
    return false;
  }

  return Boolean(article.id && article.slug && article.content.title);
};
