import Vue from 'vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import ArticleInfoRibbon from '@root/modules/article/components/info/ArticleInfoRibbon.vue';
import ArticleInfoAiDisclaimer from '@root/modules/article/components/info/ArticleInfoAiDisclaimer.vue';
import ArticleInfoCover from '@root/modules/article/components/info/ArticleInfoCover.vue';
import ArticleInfoDecorators from '@root/modules/article/components/info/ArticleInfoDecorators.vue';
import ArticleInfoSummary from '@root/modules/article/components/info/ArticleInfoSummary.vue';
import ArticleInfoLead from '@root/modules/article/components/info/ArticleInfoLead.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import ContentFragment from '@core/components/ContentFragment.vue';
import ListenRibbon from '@root/modules/article/components/info/ListenRibbon.vue';
import { Data as ArticleData } from '@root/modules/article/types/article';
import { buildRelativeRoute, extendRoutePath } from '@root/router/utils/buildRoutePath';

interface Props {
  article: ArticleData;
  showBodyFragments: number;
  adFree: boolean;
  isAdultConfirmed: boolean;
}

interface Computed {
  leadPosition: string;
  articleCommentsPath: string;
  categoryUrl: string;
  articleSettings: ArticleData['settings'];
  comments: boolean | number;
  disabledAdsList: string[];
  disableAds: boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    AdFormBanner,
    ArticleInfoCover,
    ArticleInfoAiDisclaimer,
    ArticleInfoDecorators,
    ArticleInfoSummary,
    ArticleInfoRibbon,
    ArticleInfoLead,
    ContentFragment,
    LinkHandler,
    ListenRibbon,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    adFree: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdultConfirmed: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBodyFragments: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    categoryUrl() {
      const pathOptions = {
        type: 'category',
        id: this.article.primaryCategory.id,
        slug: this.article.primaryCategory.slug,
        channel: this.article.primaryCategory.channel,
      };
      return buildRelativeRoute({ pathOptions });
    },
    leadPosition() {
      const articleSettings = this.$channelConfig('page').article;
      return articleSettings.component.info.leadPosition;
    },
    articleCommentsPath() {
      const pathOptions = {
        type: 'article',
        id: this.article.id,
        slug: this.article.slug,
        channel: this.article.primaryCategory.channel,
      };

      const articleUrl = buildRelativeRoute({ pathOptions });
      return extendRoutePath({ route: articleUrl, suffix: String(this.$t('portal_core.comments')) });
    },
    articleSettings() {
      return this.article.settings || {};
    },
    comments() {
      const { disableComments } = this.article.comments;
      const { comments } = this.article.statistics;
      return !disableComments && comments > 0 && comments;
    },
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
    disableAds() {
      return this.article.banners.disableAds;
    },
  },
});
